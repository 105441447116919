<template>
  <div>
    <b-custom-spinner
      style="
        z-index: 999;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        overflow-y: hidden;
      "
      :is_progress="is_progress"
    ></b-custom-spinner>

    <b-card title="Filtreler">
      <b-row class="mb-1">
        <b-col>
          <v-select
            placeholder="Detaylı Arama İçin Filtre Kategorisi Ekleyiniz"
            v-model="selected_categories"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="display_name"
            :reduce="(item) => item.internal_name"
            :options="categories"
            multiple
            :closeOnSelect="false"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-row>
            <b-col cols="3">
              <date-range-picker
                auto-apply
                append-to-body
                class="d-block w-100"
                v-model="filter_values.dateRanges"
                :ref="`picker${i}`"
                :locale-data="{
                  direction: 'ltr',
                  format: 'dd/mm/yyyy HH:mm',
                  separator: ' - ',
                  applyLabel: 'Uygula',
                  cancelLabel: 'İptal',
                  weekLabel: 'H',
                  customRangeLabel: 'Özel Aralık',
                  daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
                  monthNames: [
                    'Oca',
                    'Şub',
                    'Mar',
                    'Nis',
                    'May',
                    'Haz',
                    'Tem',
                    'Ağu',
                    'Eyl',
                    'Eki',
                    'Kas',
                    'Ara',
                  ],
                  firstDay: 1,
                }"
                :timePicker="true"
                :timePicker24Hour="true"
                :time-picker-seconds="false"
              >
                <template v-slot:input="picker">
                  <span>
                    {{ setDateFormat(picker.startDate) }} -
                    {{ setDateFormat(picker.endDate) }}
                  </span>
                </template>
                <template #ranges="ranges">
                  <div class="ranges">
                    <b-row
                      class="mx-25"
                      v-for="(range, name) in ranges.ranges"
                      :key="name"
                    >
                      <b-col>
                        <b-button
                          size="sm"
                          block
                          @click="ranges.clickRange(range)"
                          variant="flat-primary"
                        >
                          {{ name }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <div slot="footer" slot-scope="data" class="slot">
                  <div
                    class="border-top px-1 py-1 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      {{ data.rangeText }}
                    </div>
                    <div class="float-right">
                      <b-button
                        size="sm"
                        @click="data.clickApply"
                        v-if="!data.in_selection"
                        variant="flat-primary"
                      >
                        {{ lang("t_apply") }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </date-range-picker>
            </b-col>
            <b-col cols="3">
              <v-select
                placeholder="Kuyruklar"
                v-model="filter_values.Queues"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.display_name"
                :options="queues"
                multiple
                :closeOnSelect="false"
              />
            </b-col>
            <b-col cols="3">
              <v-select
                placeholder="Temsilciler"
                v-model="filter_values.Users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.identifier.replace('@', '_')"
                :options="users"
                multiple
                :closeOnSelect="false"
              />
            </b-col>
            <b-col cols="3">
              <v-select
                placeholder="Yön"
                v-model="filter_values.CallDirections"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="call_direction_options"
                multiple
                :closeOnSelect="false"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-1" v-if="selected_categories.includes('details')">
        <b-col>
          <div>
            <h3>Detaylar</h3>
          </div>
          <b-row>
            <b-col cols="3">
              <v-select
                placeholder="Konuşma Seçeneği"
                v-model="filter_values.SpeechOption"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="speech_options"
                :closeOnSelect="true"
              />
            </b-col>
            <b-col cols="3">
              <v-select
                placeholder="Anahtar Kelime Kategorisi"
                v-model="filter_values.KeyWordSpottings"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.callexper_id"
                :options="keyword_categories"
                multiple
                :closeOnSelect="false"
              >
                <template #list-header>
                  <div
                    class="my-50 mx-50 d-flex justify-content-center align-items-center"
                  >
                    <b-form-radio
                      class="mr-1"
                      v-model="filter_values.KeyWordSpottingsLogicRule"
                      value="0"
                      @change="updateKeyWordSpottingsLogicRule(0)"
                    >
                      Ve
                    </b-form-radio>
                    <b-form-radio
                      class="ml-1"
                      v-model="filter_values.KeyWordSpottingsLogicRule"
                      value="1"
                      @change="updateKeyWordSpottingsLogicRule(1)"
                    >
                      Veya
                    </b-form-radio>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col cols="3">
              <b-form-input
                v-model="filter_values.RemoteNumber"
                placeholder="Telefon"
                trim
              ></b-form-input>
            </b-col>
            <b-col cols="3">
              <b-form-input
                v-model="filter_values.PbxCallId"
                placeholder="Çağrı ID"
                trim
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="3">
              <v-select
                placeholder="Kapanma Nedeni"
                v-model="filter_values.TerminateReasons"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="terminate_options"
                multiple
                :closeOnSelect="false"
              />
            </b-col>

            <b-col cols="3">
              <v-select
                placeholder="Dinlemeler"
                v-model="filter_values.Listens"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="listen_options"
                :closeOnSelect="true"
              />
            </b-col>
            <b-col cols="3">
              <v-select
                placeholder="Dinleyen"
                v-model="filter_values.Listener"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.identifier.replace('@', '_')"
                :options="users"
                :closeOnSelect="true"
              />
            </b-col>

            <b-col cols="3">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="filter_values.DurationMin"
                  class="mr-50"
                  type="number"
                  placeholder="Süre Başlangıç"
                  trim
                ></b-form-input>
                <b-form-input
                  v-model="filter_values.DurationMax"
                  class="ml-50"
                  type="number"
                  placeholder="Süre Bitiş"
                  trim
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        class="mt-1"
        v-if="selected_categories.includes('quality_management')"
      >
        <b-col>
          <div>
            <h3>Kalite Yönetimi</h3>
          </div>
          <b-row>
            <b-col cols="4">
              <v-select
                placeholder="Değerlendirme Seçeneği"
                v-model="filter_values.EvaluationOption"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="evaluate_options"
                :closeOnSelect="true"
              >
              </v-select>
            </b-col>

            <b-col cols="4">
              <v-select
                placeholder="Kalite Formları"
                v-model="filter_values.QmForms"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.callexper_id"
                :options="[]"
                multiple
                :closeOnSelect="false"
              >
                <template #list-header>
                  <div
                    class="my-50 mx-50 d-flex justify-content-center align-items-center gap-3"
                  >
                    <b-form-radio
                      class="mr-1"
                      v-model="filter_values.QmFormsLogicRule"
                      value="0"
                    >
                      Ve
                    </b-form-radio>
                    <b-form-radio
                      class="ml-1"
                      v-model="filter_values.QmFormsLogicRule"
                      value="1"
                    >
                      Veya
                    </b-form-radio>
                  </div>
                </template>
              </v-select>
            </b-col>
            <b-col cols="4">
              <div class="d-flex align-items-center">
                <v-select
                  class="w-100"
                  placeholder="Operatör"
                  v-model="filter_values.QmFormPointOption"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="display_name"
                  :reduce="(item) => item.id"
                  :options="operand_options"
                  :closeOnSelect="true"
                >
                </v-select>

                <b-form-input
                  v-model="filter_values.QmFormPoint"
                  class="ml-1"
                  type="number"
                  placeholder="Puan"
                  trim
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <v-select
                placeholder="Kaliteci"
                v-model="filter_values.Evaluators"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.identifier.replace('@', '_')"
                :options="users"
                multiple
                :closeOnSelect="false"
              />
            </b-col>

            <b-col cols="4">
              <date-range-picker
                auto-apply
                append-to-body
                class="d-block w-100"
                v-model="filter_values.EvaluationDateRanges"
                :ref="`picker${i}`"
                :locale-data="{
                  direction: 'ltr',
                  format: 'dd/mm/yyyy HH:mm',
                  separator: ' - ',
                  applyLabel: 'Uygula',
                  cancelLabel: 'İptal',
                  weekLabel: 'H',
                  customRangeLabel: 'Özel Aralık',
                  daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
                  monthNames: [
                    'Oca',
                    'Şub',
                    'Mar',
                    'Nis',
                    'May',
                    'Haz',
                    'Tem',
                    'Ağu',
                    'Eyl',
                    'Eki',
                    'Kas',
                    'Ara',
                  ],
                  firstDay: 1,
                }"
                :timePicker="true"
                :timePicker24Hour="true"
                :time-picker-seconds="false"
              >
                <template v-slot:input="picker">
                  <span>
                    Değerlendirme Tarihi:
                    {{ setDateFormat(picker.startDate) }} -
                    {{ setDateFormat(picker.endDate) }}
                  </span>
                </template>
                <template #ranges="ranges">
                  <div class="ranges">
                    <b-row
                      class="mx-25"
                      v-for="(range, name) in ranges.ranges"
                      :key="name"
                    >
                      <b-col>
                        <b-button
                          size="sm"
                          block
                          @click="ranges.clickRange(range)"
                          variant="flat-primary"
                        >
                          {{ name }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <div slot="footer" slot-scope="data" class="slot">
                  <div
                    class="border-top px-1 py-1 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      {{ data.rangeText }}
                    </div>
                    <div class="float-right">
                      <b-button
                        size="sm"
                        @click="data.clickApply"
                        v-if="!data.in_selection"
                        variant="flat-primary"
                      >
                        {{ lang("t_apply") }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </date-range-picker>
            </b-col>

            <b-col cols="4">
              <v-select
                placeholder="İtiraz Seçeneği"
                v-model="filter_values.ObjectionOption"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="objection_options"
                :closeOnSelect="true"
              >
              </v-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-1" v-if="selected_categories.includes('speech')">
        <b-col>
          <div>
            <h3>Konuşma</h3>
          </div>
          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-input
                v-model="filter_values.SearchAgent"
                placeholder="Metin Ara (Temsilci)"
                trim
              ></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-form-input
                v-model="filter_values.SearchCustomer"
                placeholder="Metin Ara (Müşteri)"
                trim
              ></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-form-input
                v-model="filter_values.SearchBoth"
                placeholder="Metin Ara (İki Tarafta)"
                trim
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        class="mt-1"
        v-if="selected_categories.includes('advance_parameters')"
      >
        <b-col>
          <div>
            <h3>Gelişmiş Parametreler</h3>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <b-button class="float-right" variant="primary" @click="fetchRecords"
            >Getir</b-button
          >
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col :cols="displaySound ? '8' : '12'">
          <b-card style="height: 62vh; overflow-y: auto" no-body class="">
            <b-row class="mx-0 mt-1">
              <b-col class="d-flex align-items-center">
                <h4 class="my-0 pl-1">Kayıtlar</h4>
              </b-col>
              <b-col cols="8">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Konuşma, Müşteri Adı, Telefon vs..."
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-card-body>
              <div style="display: grid; overflow-x: auto">
                <b-table
                  :tbody-tr-class="rowClassRecords"
                  :filter="searchQuery"
                  style="min-height: 28.85rem; overflow: inherit"
                  small
                  @row-dblclicked="load_sound"
                  ref="refInvoiceListTable"
                  :items="records"
                  responsive
                  hover
                  :fields="tableColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                  class="position-relative mt-"
                >
                  <template #cell(StationName)="data">
                    {{ data.value.split("_")[0] }}
                  </template>
                  <template #cell(StartDate)="data">
                    {{ data.value.replace("T", " ").slice(0, 19) }}
                  </template>
                  <template #cell(PhoneNumber)="data">
                    {{ data.value.slice(-10) }}
                  </template>
                  <template #cell(QueueName)="data">
                    <b-badge variant="light-primary">
                      {{
                        queues.find((e) => e.internal_name == data.value)
                          ? queues.find((e) => e.internal_name == data.value)
                              .display_name
                          : data.value
                      }}
                    </b-badge>
                  </template>

                  <template #cell(Inbound)="data">
                    <div class="d-flex align-items-center">
                      <b-icon
                        icon="telephone-inbound"
                        v-if="data.value == true"
                      ></b-icon>
                      <b-icon icon="telephone-outbound" v-else></b-icon>

                      <span class="ml-50">{{
                        data.value ? "inbound" : "outbound"
                      }}</span>
                    </div>
                  </template>
                  <template #cell(AutoEvaluations)="data">
                    {{ getAutoEvaluationsName(data.value) }}
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown
                      boundary="scrollParent"
                      :popper-opts="{
                        modifiers: {
                          computeStyle: { gpuAcceleration: false },
                        },
                      }"
                      dropleft
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        @click="getCallDetails(data.item, 'email')"
                      >
                        <i class="bi bi-envelope"></i>
                        <span class="align-middle ml-50">E-Posta</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="getCallDetails(data.item, 'add_info')"
                      >
                        <i class="bi bi-patch-plus"></i>
                        <span class="align-middle ml-50">Bilgi Ekle</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="getCallDetails(data.item, 'special_info')"
                      >
                        <i class="bi bi-person-bounding-box"></i>
                        <span class="align-middle ml-50">Özel Bilgi</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="getCallDetails(data.item, 'listens')"
                      >
                        <i class="bi bi-play-btn"></i>
                        <span class="align-middle ml-50">Dinlemeler</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="getCallDetails(data.item, 'speech_analatics')"
                      >
                        <i class="bi bi-clipboard-data"></i>
                        <span class="align-middle ml-50"
                          >Konuşma İstatistikleri</span
                        >
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="
                          getCallDetails(data.item, 'sentimental_analatics')
                        "
                      >
                        <i class="bi bi-bar-chart"></i>
                        <span class="align-middle ml-50"
                          >Duygu İstatistikleri</span
                        >
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="getCallDetails(data.item, 'media_analatics')"
                      >
                        <i class="bi bi-diagram-3"></i>
                        <span class="align-middle ml-50"
                          >Medya İstatistikleri</span
                        >
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="getCallDetails(data.item, 'signal_logs')"
                      >
                        <i class="bi bi-reception-2"></i>

                        <span class="align-middle ml-50"
                          >Sinyalleşme Logları</span
                        >
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </b-card-body>
            <b-card-footer class="py-75 border-0">
              <div
                class="d-flex justify-content-between d-flex align-items-center"
              >
                <div class="d-flex align-items-center mb-0">
                  <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                  <b-form-select
                    size="sm"
                    v-model="perPage"
                    :options="['10', '25', '50']"
                    class="mr-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap text-muted font-small-3">
                    {{
                      lang("t_total") +
                      " " +
                      totalRows +
                      " " +
                      lang("t_row").toLowerCase()
                    }}
                  </span>
                </div>

                <b-pagination
                  @input="fetchRecords()"
                  size="sm"
                  class="mb-0"
                  v-model="currentPage"
                  :per-page="perPage"
                  align="right"
                  :total-rows="totalRows"
                  first-number
                  last-number
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="16" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="16" />
                  </template>
                </b-pagination>
              </div>
            </b-card-footer>
          </b-card>
        </b-col>
        <b-col v-if="displaySound" cols="4">
          <b-card
            ref="soundCard"
            style="height: 62vh; overflow-y: auto"
            no-body
            class="mb-0 border-bottom"
          >
            <div>
              <b-row v-if="selected_record" class="border-bottom mx-0">
                <b-col>
                  <b-row align-h="between" align-v="center">
                    <b-col>
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-avatar
                          size="38"
                          variant="light-primary"
                          class="my-1"
                        >
                          {{
                            selected_record.StationName[0].toUpperCase() +
                            selected_record.StationName.split(
                              "."
                            )[1][0].toUpperCase()
                          }}
                        </b-avatar>
                        <!-- <span class="ml-1 font-weight-bold">{{
                          selected_record.StationName.split("_")[0]
                        }}</span> -->

                        <!-- <span>{{ displayKeyWordCategories() }}</span> -->
                        <!-- <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          :disabled="
                            Object.keys(categorized_messages).length == 0
                          "
                          class="ml-1"
                          size="sm"
                        >
                          <template #button-content>
                            <i class="bi bi-tags font-medium-5"></i>
                          </template>

                          <b-dropdown-item
                            @click="
                              searchKeyword =
                                categorized_messages[item].keyword;
                              searchAndHighlightKeyword();
                            "
                            v-for="(item, i) in Object.keys(
                              categorized_messages
                            )"
                            :key="i"
                          >
                            {{ item }}
                          </b-dropdown-item>
                        </b-dropdown> -->

                        <b-form-input
                          size="sm"
                          class="mx-1 rounded"
                          v-model="searchKeyword"
                          @keyup="searchAndHighlightKeyword"
                        />
                        <span class="ml-1">
                          {{
                            totalMatches == 0 ? "0" : currentMatchIndex + 1
                          }}/{{ totalMatches }}
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>

            <div>
              <!-- <div style="height: 70vh; overflow-y: auto"> -->
              <div ref="msg_div" class="messages mt-1">
                <div v-for="(message, i) in messages" :key="i">
                  <b-row class="px-1">
                    <b-col>
                      <span
                        :class="[
                          message.type == 'customer'
                            ? 'msg_from bg-light-secondary'
                            : 'msg_to bg-light-primary',
                          checkKeywordCategory(message.Text) == true &&
                          message.type == 'customer'
                            ? 'border-secondary'
                            : checkKeywordCategory(message.Text) == true &&
                              message.type == 'agent'
                            ? 'border-primary'
                            : '',
                        ]"
                      >
                        <span
                          v-for="(word, wordIndex) in message.Words"
                          :key="wordIndex"
                          :class="{
                            highlight: isWordPlaying(word) || word.highlight,
                            'font-weight-bolder': word.overlapping,
                          }"
                        >
                          {{ word.Name }}
                        </span>

                        <small class="time">
                          <div
                            class="d-flex align-items-center justify-content-end gap-1"
                          >
                            <i
                              v-if="message.Sentiment.Result == 1"
                              class="bi bi-record-circle text-danger"
                            ></i>
                            <i
                              v-if="message.Sentiment.Result == 2"
                              class="bi bi-record-circle text-success ml-50"
                            ></i>
                            <i
                              v-if="message.HighGainDuration > 0"
                              class="bi bi-graph-up-arrow text-warning ml-50"
                            ></i>
                            <i
                              v-if="message.LowGainDuration > 0"
                              class="bi bi-graph-down-arrow text-danger ml-50"
                            ></i>
                            <span class="ml-1">{{ message.Start }}</span>
                          </div>
                        </small>
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        :class="displaySound ? 'd-block' : 'd-none'"
        style="
          position: fixed;
          bottom: 0;
          z-index: 9999;
          left: 0;
          width: 100vw;
          margin-left: 0;
          margin-right: 0;
        "
      >
        <b-col class="px-0">
          <b-card class="mb-0">
            <b-card-text>
              <b-row class="mx-0">
                <b-col
                  class="d-flex align-items-center justify-content-center px-0"
                >
                  <b-dropdown
                    v-if="wavesurfer"
                    class="mr-50 ml-2"
                    :text="wavesurfer.getPlaybackRate() + 'x'"
                    variant="flat-secondary"
                  >
                    <b-dropdown-item @click="setSoundSpeed(0.25)">
                      0.25x
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSoundSpeed(0.5)">
                      0.5x
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSoundSpeed(0.75)">
                      0.75x
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSoundSpeed(1)">
                      1x
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSoundSpeed(1.25)">
                      1.25x
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSoundSpeed(1.5)">
                      1.50x
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSoundSpeed(1.75)">
                      1.75x
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSoundSpeed(2)">
                      2x
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="20" icon="SkipBackIcon" />
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    class="btn-icon rounded-circle mx-50"
                    @click="
                      () => {
                        wavesurfer.playPause();
                      }
                    "
                  >
                    <feather-icon
                      v-if="wavesurfer"
                      size="20"
                      :icon="
                        wavesurfer && wavesurfer.isPlaying()
                          ? 'PauseIcon'
                          : 'PlayIcon'
                      "
                    />
                  </b-button>
                  <b-button
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="20" icon="SkipForwardIcon" />
                  </b-button>
                  <span style="width: 3rem" class="ml-3">
                    {{ `${formatTime(parseInt(currentTime))}` }}
                  </span>
                  <b-col class="cursor-pointer" :id="`fdwaveform`"></b-col>
                  <span
                    style="width: 3rem"
                    v-if="wavesurfer && wavesurfer.decodedData"
                  >
                    {{
                      `${formatTime(parseInt(wavesurfer.decodedData.duration))}`
                    }}
                  </span>
                  <b-button
                    @click="closeSound"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle ml-5"
                  >
                    <feather-icon size="20" icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      body-class="my-0 py-0 mx-0 px-0"
      hide-footer
      hide-header
      id="modal-key-word"
      dialog-class="dClass75"
      centered
    >
      <key-word></key-word>
    </b-modal>

    <b-modal
      title="Sinyalleşme Logları"
      v-if="selected_record_details"
      v-model="modals.signal_logs"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.SignalingLogs"
          :fields="signal_logs_fields"
        >
          <template #cell(MessageDate)="data">
            {{ new Date(data.value).toLocaleTimeString() }}
          </template>
          <template #cell(Message)="data">
            <b-link @click="openSignalLogDetails(data.item)">{{
              data.item.Request ? data.item.Request : data.item.Response
            }}</b-link>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal
      title="Medya İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.media_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div
        class="d-flex align-items-center justify-content-center flex-wrap"
        style="gap: 8px"
      >
        <b-card
          v-for="(item, i) in [
            'RtpPacketCountIn',
            'RtpPacketCountOut',
            'DublicateRtpPacketCountIn',
            'DublicateRtpPacketCountOut',
            'LostRtpPacketCountIn',
            'LostRtpPacketCountOut',
            'FirstRtpPacketIn',
            'FirstRtpPacketOut',
            'LastRtpPacketIn',
            'LastRtpPacketOut',
            'CodecIn',
            'CodecOut',
            'RtpEncryptionIn',
            'RtpEncryptionOut',
          ]"
          class="bg-light-primary"
          no-body
          style="flex-basis: calc(100% / 7 - 8px)"
          :key="i"
        >
          <div class="text-center py-1 px-1">
            <span
              v-b-tooltip.hover.top="item"
              class="d-inline-block text-truncate"
              style="max-width: 99%"
              >{{ item }}</span
            >

            <h2
              v-if="
                [
                  'FirstRtpPacketIn',
                  'FirstRtpPacketOut',
                  'LastRtpPacketIn',
                  'LastRtpPacketOut',
                ].includes(item)
              "
            >
              {{
                new Date(
                  selected_record_details.MediaLog[item]
                ).toLocaleTimeString()
              }}
            </h2>
            <h2
              v-else-if="
                ['CodecIn', 'CodecOut'].includes(item) &&
                selected_record_details.MediaLog[item].length == 0
              "
            >
              {{ "-" }}
            </h2>

            <h2 v-else>
              {{ selected_record_details.MediaLog[item] }}
            </h2>
          </div>
        </b-card>
      </div>
      <div class="border-bottom">
        <h2 class="text-primary">Sources In</h2>
      </div>
      <b-row class="py-1">
        <b-col cols="6">
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].IpPortLen }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].FirstDate }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].LastDate }}
          </span>
        </b-col>
      </b-row>
      <div class="border-bottom">
        <h2 class="text-primary">Sources Out</h2>
      </div>
      <b-row class="py-1">
        <b-col cols="6">
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].IpPortLen }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].FirstDate }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].LastDate }}
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Duygu İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.sentimental_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <b-row>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Temsilci</span
              >
              <small class="text-muted">Temsilci konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in sentiment_object"
              :key="i"
              class="mt-auto"
              :class="[
                [
                  'SentimentPositiveSegmentRate',
                  'SentimentNegativeSegmentRate',
                ].includes(item.internal_name)
                  ? 'mb-3'
                  : 'mb-1',
              ]"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[1][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Müşteri</span
              >
              <small class="text-muted">Müşteri konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in sentiment_object"
              :key="i"
              class="mt-auto"
              :class="[
                [
                  'SentimentPositiveSegmentRate',
                  'SentimentNegativeSegmentRate',
                ].includes(item.internal_name)
                  ? 'mb-3'
                  : 'mb-1',
              ]"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[0][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Konuşma İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.speech_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <!-- <b-row class="d-flex align-items-center">
        <b-col class="d-flex align-items-center border rounded">
      
          <div class="d-flex align-items-center justify-content-center">
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Üstüste Konuşma Oranı</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Üstüste Konuşma Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Toplam Üstüste Konuşma Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
          </div>
        </b-col>
        <b-col class="d-flex align-items-center border rounded">
          <div class="d-flex align-items-center justify-content-center">
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 texspeecht-truncate" style="width: 99%"
                >Sessizlik Oranı</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Sessizlik Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Toplam Sessizlik Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
          </div>
        </b-col>
      </b-row> -->

      <b-row v-if="selected_record_details">
        <b-col
          v-for="(item, i) in speech_object2"
          :key="i"
          md="2"
          sm="2"
          class="mt-auto mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media class="mt-2 mb-75" no-body>
            <b-media-aside class="mr-2">
              <b-avatar rounded="lg" size="64" :variant="item.color">
                <!-- <feather-icon size="28" :icon="item.icon" /> -->
                <i :class="[item.icon, 'font-medium-3']"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h2 class="font-weight-bolder mb-0">
                {{
                  parseInt(selected_record_details.Speech[item.internal_name])
                }}
              </h2>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Temsilci</span
              >
              <small class="text-muted">Temsilci konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in speech_object"
              :key="i"
              class="mt-auto mb-1"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[1][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Müşteri</span
              >
              <small class="text-muted">Müşteri konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in speech_object"
              :key="i"
              class="mt-auto mb-1"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[0][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Bilgi Ekle"
      v-if="selected_record_details"
      v-model="modals.add_info"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          style="max-height: 400px; overflow-y: auto"
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.Attachments"
          :fields="attechments_fields"
        >
          <template #cell(Date)="data">
            <span class="text-nowrap">
              {{
                new Date(new Date(data.value).getTime() + 3 * 60 * 60 * 1000)
                  .toISOString()
                  .replace(/([^T]+)T([^\.]+).*/g, "$1 $2")
              }}
            </span>
          </template>
        </b-table>
      </div>
      <hr />
      <b-row>
        <b-col cols="3">
          <b-form-group label="Saniye">
            <b-form-input
              v-model="attechment_info.Second"
              type="number"
              placeholder="Saniye"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group label="Yorum">
            <b-form-input
              v-model="attechment_info.Comment"
              placeholder="Yorum"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-1">
        <b-button @click="setAttachData" class="ml-auto" variant="primary"
          >Bilgi Ekle</b-button
        >
      </div>
    </b-modal>
    <b-modal
      title="Dinlemeler"
      v-if="selected_record_details"
      v-model="modals.listens"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          style="max-height: 400px; overflow-y: auto"
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.Listens"
          :fields="listens_fields"
        >
          <template #cell(Date)="data">
            <span class="text-nowrap">
              {{
                new Date(new Date(data.value).getTime() + 3 * 60 * 60 * 1000)
                  .toISOString()
                  .replace(/([^T]+)T([^\.]+).*/g, "$1 $2")
              }}
            </span>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal
      title="Özel Bilgi"
      v-if="selected_record_details && customer"
      v-model="modals.special_info"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <customer-details :selected_customer="customer"></customer-details>
    </b-modal>
    <b-modal
      title="Email"
      v-if="selected_record_details"
      v-model="modals.email"
      dialog-class="dClass75"
      centered
      ok-only
      ok-title="Gönder"
      @ok="SendEmail"
    >
      <b-row class="mb-1">
        <!-- <b-col>
          <b-form-checkbox
            v-model="email_info.Me"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Kendim") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col> -->
        <b-col>
          <b-form-checkbox
            v-model="email_info.Agent"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("t_agent") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="email_info.SendLink"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Bağlantı") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="email_info.Details"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Detaylar") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="CC">
            <b-form-input
              v-model="email_info.CC"
              placeholder="CC"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Konu">
            <b-form-input
              v-model="email_info.Subject"
              placeholder="Konu"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Açıklama">
            <b-form-textarea
              rows="5"
              v-model="email_info.Explanation"
              placeholder="Açıklama"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BButton, BFormCheckbox, BRow, BCol,
  BFormSelect, BCardText, BPagination, BCardFooter, BCardBody, BInputGroupPrepend, BDropdown, BDropdownItem, BBadge, BLink,
  BFormRadio
} from 'bootstrap-vue'
import vSelect from "vue-select";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import WaveSurfer from 'wavesurfer.js'
import CustomerDetails from './CustomerDetails.vue';
import { $themeColors } from "@themeConfig";

const startDate = new Date();
startDate.setHours(0, 0, 0, 0);
const endDate = new Date();
endDate.setHours(23, 55, 0, 0);
export default {
  components: {
    BFormSelect, BCardText, BPagination, BCardFooter, BCardBody, BInputGroupPrepend,
    DateRangePicker, BDropdown, BDropdownItem, BBadge, BLink, BFormRadio,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup, BFormInput,
    BCard,
    BTable,
    BAvatar,
    BImg,
    vSelect,
    BFormCheckbox,
    BRow, BCol,
    CustomerDetails,
  },

  computed: {

  },

  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      categories: [
        { internal_name: 'details', display_name: 'Detaylar' },
        { internal_name: 'quality_management', display_name: 'Kalite Yönetimi' },
        { internal_name: 'speech', display_name: 'Konuşma' },
        // { internal_name: 'advance_parameters', display_name: 'Gelişmiş Parametreler' },
      ],
      speech_options: [
        { id: 0, internal_name: 'All', display_name: 'Tümü' },
        { id: 1, internal_name: 'Analyzed', display_name: 'Analiz Edilmiş' },
        { id: 2, internal_name: 'Transcript-Error', display_name: 'Transcript Hatası' },
        { id: 3, internal_name: 'Full-Speech', display_name: 'İki Kanal Konuşma' },
        { id: 4, internal_name: 'Half-Speech', display_name: 'Tek Kanal Konuşma' },
      ],

      terminate_options: [
        { id: 0, internal_name: 'Müşteri Kapattı', display_name: 'Müşteri Kapattı' },
        { id: 1, internal_name: 'Temsilci Kapattı', display_name: 'Temsilci Kapattı' },
        { id: 2, internal_name: 'Aktarma', display_name: 'Aktarma' },
        { id: 3, internal_name: 'Sip Zaman Aşımı', display_name: 'Sip Zaman Aşımı' },
        { id: 4, internal_name: 'Rtp Zaman Aşımı', display_name: 'Rtp Zaman Aşımı' },
      ],

      listen_options: [
        { id: 0, internal_name: 'Tümü', display_name: 'Tümü' },
        { id: 1, internal_name: 'Dinlenmiş', display_name: 'Dinlenmiş' },
        { id: 2, internal_name: 'Dinlenmemiş', display_name: 'Dinlenmemiş' }
      ],

      evaluate_options: [
        { id: 0, internal_name: 'Tümü', display_name: 'Tümü' },
        { id: 1, internal_name: 'Değerlendirilmiş', display_name: 'Değerlendirilmiş' },
        { id: 2, internal_name: 'Değerlendirilmemiş', display_name: 'Değerlendirilmemiş' },
        { id: 3, internal_name: 'Otomatik Değerlendirme', display_name: 'Otomatik Değerlendirme' }
      ],

      operand_options: [
        { id: 0, internal_name: 'Tümü', display_name: 'Tümü' },
        { id: 1, internal_name: 'Eşit', display_name: 'Eşit' },
        { id: 2, internal_name: 'Büyük Eşit', display_name: 'Büyük Eşit' },
        { id: 3, internal_name: 'Küçük Eşit', display_name: 'Küçük Eşit' }
      ],

      objection_options: [
        { id: 0, internal_name: 'Tümü', display_name: 'Tümü' },
        { id: 1, internal_name: 'İtiraz Edilmiş', display_name: 'İtiraz Edilmiş' },
        { id: 2, internal_name: 'Cevaplanmış', display_name: 'Cevaplanmış' },
        { id: 3, internal_name: 'Cevaplanmamış', display_name: 'Cevaplanmamış' }
      ],
      call_direction_options: [
        { id: 0, internal_name: 'inbound', display_name: 'Gelen Çağrı' },
        { id: 1, internal_name: 'outbound', display_name: 'Gİden Çağrı' },

      ],


      selected_categories: ['details'],
      filter_values: {
        "dateRanges": {
          "startDate": startDate.toISOString(),
          "endDate": endDate.toISOString(),
        },
        "Queues": [],
        "Users": [],
        "CallDirections": [],
        "SpeechOption": 1,
        "KeyWordSpottings": [],
        "KeyWordSpottingsLogicRule": "1",
        "TerminateReasons": [],
        "QmForms": [],
        "QmFormsLogicRule": "1",
        "QmFormPointOption": 0,
        "QmFormPoint": null,
        "Evaluators": [],
        "EvaluationDateRanges": {
          "startDate": null,
          "endDate": null,
        },
        "ObjectionOption": 0,
        "SearchAgent": "",
        "SearchCustomer": "",
        "SearchBoth": "",
        "EvaluationOption": "",
        "Listens": 0,
        "Listener": "",
        "DurationMin": 0,
        "DurationMax": 0,
        "PbxCallId": "",
        "RemoteNumber": ""
      },
      searchQuery: '',
      is_progress: false,
      SpeechRules: null,
      customer: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      modals: {
        email: false,
        add_info: false,
        special_info: false,
        listens: false,
        speech_analatics: false,
        sentimental_analatics: false,
        media_analatics: false,
        signal_logs: false,
        signal_log_details: false,
      },
      sentiment_object: [
        { "internal_name": "SentimentPositiveSegmentCount", "display_name": "Pozitif Segment Sayısı", "icon": "bi bi-emoji-smile", color: 'success' },
        { "internal_name": "SentimentPositiveScore", "display_name": "Pozitif Skor", "icon": "bi bi-graph-up", color: 'success' },
        { "internal_name": "SentimentPositiveTokenCount", "display_name": "Pozitif Token Sayısı", "icon": "bi bi-check", color: 'success' },
        { "internal_name": "SentimentPositiveSegmentRate", "display_name": "Pozitif Segment Oranı (%)", "icon": "bi bi-pie-chart", color: 'success' },
        { "internal_name": "SentimentNegativeSegmentCount", "display_name": "Negatif Segment Sayısı", "icon": "bi bi-emoji-frown", color: 'danger' },
        { "internal_name": "SentimentNegativeScore", "display_name": "Negatif Skor", "icon": "bi bi-graph-down", color: 'danger' },
        { "internal_name": "SentimentNegativeTokenCount", "display_name": "Negatif Token Sayısı", "icon": "bi bi-x", color: 'danger' },
        { "internal_name": "SentimentNegativeSegmentRate", "display_name": "Negatif Segment Oranı (%)", "icon": "bi bi-pie-chart", color: 'danger' },
        { "internal_name": "SentimentNotrSegmentRate", "display_name": "Nötr Segment Oranı (%)", "icon": "bi bi-dash", color: 'secondary' },
        { "internal_name": "SentimentTotalScore", "display_name": "Toplam Skor", "icon": "bi bi-123", color: 'info' },

      ],
      speech_object: [
        { "internal_name": "SpeechDuration", "display_name": "Konuşma Süresi", "icon": "bi bi-clock", "color": "danger" },
        { "internal_name": "SpeechRatio", "display_name": "Konuşma Oranı", "icon": "bi bi-bar-chart", "color": "success" },
        { "internal_name": "SpeechingRate", "display_name": "Konuşma Hızı (Kelime/Dakika)", "icon": "bi bi-speedometer2", "color": "success" },
        { "internal_name": "InterruptCount", "display_name": "Söz Kesme Sayısı", "icon": "bi bi-x-circle", "color": "success" },
        { "internal_name": "LowGainDuration", "display_name": "Monoton Konuşma Süresi", "icon": "bi bi-volume-down", "color": "success" },
        { "internal_name": "HighGainDuration", "display_name": "Ageresif Konuşma Süresi", "icon": "bi bi-exclamation-circle", "color": "danger" }
      ],

      speech_object2: [
        {
          "icon": "bi bi-bar-chart",
          "color": "light-primary",
          "title": "% 0",
          "subtitle": "Üstüste Konuşma Oranı",
          "customClass": "mb-2 mb-xl-0",
          "internal_name": "OverlappedSpeechRatio"
        },
        {
          "icon": "bi bi-clock",
          "color": "light-primary",
          "title": "% 12",
          "subtitle": "Üstüste Konuşma Süresi",
          "customClass": "mb-2 mb-sm-0",
          "internal_name": "OverlappedSpeechDuration"
        },
        {
          "icon": "bi bi-clock-history",
          "color": "light-primary",
          "title": "0",
          "subtitle": "Toplam Üstüste Konuşma Süresi",
          "customClass": "",
          "internal_name": "OverlappedSpeechDuration"
        },
        {
          "icon": "bi bi-activity",
          "color": "light-info",
          "title": "% 0",
          "subtitle": "Sessizlik Oranı",
          "customClass": "mb-2 mb-xl-0",
          "internal_name": "OverlappedSilenceRatio"
        },
        {
          "icon": "bi bi-hourglass-split",
          "color": "light-info",
          "title": "% 12",
          "subtitle": "Sessizlik Süresi",
          "customClass": "mb-2 mb-sm-0",
          "internal_name": "OverlappedSilenceDuration"
        },
        {
          "icon": "bi bi-hourglass-bottom",
          "color": "light-info",
          "title": "0",
          "subtitle": "Toplam Sessizlik Süresi",
          "customClass": "",
          "internal_name": "OverlappedSilenceDuration"
        }
      ],

      signal_logs_fields: [
        { key: 'MessageDate', label: 'MessageDate', sortable: true },
        { key: 'Message', label: 'Message', sortable: true },
        { key: 'From', label: 'From', sortable: true },
        { key: 'To', label: 'To', sortable: true },
        { key: 'Transport', label: 'Transport', sortable: true },
        { key: 'SrcIpPort', label: 'SrcIpPort', sortable: true },
        { key: 'DstIpPort', label: 'DstIpPort', sortable: true },
      ],
      listens_fields: [
        { key: 'Date', label: 'Tarih', sortable: true },
        { key: 'UserId', label: 'Temsilci', sortable: true },
      ],
      attechments_fields: [
        { key: 'Date', label: 'Tarih', sortable: true },
        { key: 'UserId', label: 'Temsilci', sortable: true },
        { key: 'Second', label: 'Saniye', sortable: true },
        { key: 'Comment', label: 'Yorum', sortable: true },
      ],
      attechment_info: {
        Comment: '',
        Second: 0
      },
      email_info: {
        Me: false,
        Agent: false,
        CC: '',
        Subject: 'Çağrı Kaydı Maili',
        SendLink: false,
        Explanation: '',
        Details: false,
      },
      currentTime: 0,
      displaySound: false,

      wavesurfer: null,
      keyword_categories: [],
      messages: [],
      tableColumns: [
        { key: 'Inbound', label: 'Yön', sortable: true, tdClass: 'text-nowrap' },
        { key: 'QueueName', label: 'Kuyruk', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StationName', label: 'Temsilci', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PbxCallId', label: 'Çağrı ID', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PhoneNumber', label: 'Numara', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StartDate', label: 'Tarih', sortable: true, tdClass: 'text-nowrap' },
        { key: 'SipKeyValue', label: 'Sonuç Kodu', sortable: true, tdClass: 'text-nowrap', },
        { key: 'Duration', label: 'Süre', sortable: true, tdClass: 'text-nowrap', },
        { key: 'AutoEvaluations', label: 'Değerlendirmeler', sortable: true, tdClass: 'text-nowrap', },
        { key: 'actions', label: '', thStyle: { width: '5vh' } },
      ],
      records: [],
      speechRegions: [],
      selected_record: null,
      categorized_messages: null,
      searchKeyword: '',
      currentMatchIndex: 0,
      totalMatches: 0,
      matchIndices: [],
      selected_record_details: null,
      visible_users: [],
      users: [],
      projects: [],
      all_queues: [],
      queues: [],
      merged_queues: [],
      selected_queue_group: [],
      queue_groups: [],
      selected_queues: [],
      selected_project: "",

    }
  },
  methods: {
    updateKeyWordSpottingsLogicRule(value) {
      this.filter_values.KeyWordSpottingsLogicRule = value;
    },
    async fetchRecords() {
      this.is_progress = true;

      if (this.filter_values.Queues.length == 0) {
        this.filter_values.Queues = this.queues.map(e => e.display_name);
      }

      let _payload = {
        offset: Number(this.currentPage) - 1,
        amount: this.perPage,
        filter_values: this.filter_values
      };

      var response = (await this.$http_in.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetRecordsAdvanceFilter`, _payload)).data;
      console.log(response);
      this.records = response.data.map(e => { return { ...e, StationName: e.Station.Name } });
      this.totalRows = response.recordsTotal;
      this.is_progress = false;

    },

    setDateFormat(date_string) {
      const date_object = new Date(date_string);

      const day = String(date_object.getDate()).padStart(2, '0');
      const month = String(date_object.getMonth() + 1).padStart(2, '0');
      const year = date_object.getFullYear();
      const hour = String(date_object.getHours()).padStart(2, '0');
      const minute = String(date_object.getMinutes()).padStart(2, '0');

      const formatted_date = `${day}/${month}/${year} ${hour}:${minute}`;
      return formatted_date;
    },
    closeSound() {
      if (this.displaySound) {
        this.displaySound = false;
        var myDiv = document.getElementById(`fdwaveform`);
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
        this.selected_record = null;
        this.matchIndices = [];
        this.totalMatches = 0;
        this.currentMatchIndex = 0;
        this.searchKeyword = '';

      }

    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

      return minutes + ":" + remainingSeconds;
    },
    setSoundSpeed(speed) {
      if (this.wavesurfer) {
        this.wavesurfer.setPlaybackRate(speed, true);
        this.wavesurfer.play();
      }
    },
    async load_sound(item) {
      try {
        this.is_progress = true;
        this.closeSound();
        this.selected_record = item;
        console.log("item", item);
        let _month = new Date(item.StartDate).getMonth()
        var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetDetails/${item._id}/${_month}`)).data;
        console.log("response123", response);
        this.SpeechRules = response.SpeechRules;

        this.messages = [];
        this.messages = response.Speech.Channels[0].SpeechRegions.map(e => { return { ...e, type: 'customer' } });
        this.messages = [...this.messages, ...response.Speech.Channels[1].SpeechRegions.map(e => { return { ...e, type: 'agent' } })];
        this.messages = this.messages.sort((a, b) => a.Start - b.Start);
        console.log("test1");

        this.messages = this.findAndTagOverlappingWords(this.messages);
        console.log("test2");
        this.categorized_messages = this.categorizeMessages();
        console.log("test3");

        this.displaySound = !this.displaySound;
        console.log("test4");

        if (this.displaySound) {
          console.log("test5");

          this.wavesurfer = globalThis.WaveSurfer.create({

            container: '#fdwaveform',
            waveColor: '#F0F0F1',
            progressColor: $themeColors.primary,
            url: response.RecordingFileName,
            "height": 60,
            barWidth: 4,
            barGap: 3,
            barRadius: 8,
          })
          console.log("this.wavesurfer", this.wavesurfer)

          this.wavesurfer.once('interaction', () => {
            console.log("interaction")
            this.wavesurfer.play()
          })

          this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);
        } else {
          var myDiv = document.getElementById("fdwaveform");
          myDiv.innerHTML = null;
          this.wavesurfer = null;
          this.currentTime = 0;
        }
      } catch (error) {
      } finally {
        this.is_progress = false;
      }

    },
    checkKeywordCategory(word) {
      if (this.SpeechRules) {
        for (const _word of word.split(' ')) {
          let object = this.SpeechRules.filter(e => e.Result == true).find(r => r.KeyWords.includes(_word));
          // console.log("checkKeywordCategory", _word, object);
          if (object) {
            // console.log("GELDİ")
            return true
            // return this.dashboard_data["ByCategory"][object.Id].Name
          }
        }
      }


      return false;

    },
    displayKeyWordCategories() {
      return this.categorized_messages
        .filter(item => item.categorized_keywords.length > 0)
        .map(item => item.categorized_keywords[0].category);
    },

    getAutoEvaluationsName(data) {
      if (data && data.length > 0) {
        let results = [];
        data.forEach((evaluation, index) => {
          let formPoint = evaluation.Form.Point;
          let result = `O:${index + 1}-${formPoint}`;
          results.push(result);
        });
        return results;
      }
      return '';

    },
    openSignalLogDetails(item) {
      this.$swal({
        title: item.Request ? item.Request : item.Response,
        width: 750,
        html: '<pre style="text-align:left;width:100%;height:300px;">' + item.Message + '</pre>',
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

    },
    async getCallDetails(item, modal) {
      this.is_progress = true;
      let _month = new Date(item.StartDate).getMonth()
      var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetDetails/${item._id}/${_month}`)).data;
      this.selected_record_details = response;
      this.selected_record = item;
      if (modal) {
        if (modal == 'special_info') {
          await this.GetCustomerByInteractionID(item);
        }
        this.modals[modal] = true;
      }
      this.is_progress = false;
    },
    async GetCustomerByInteractionID(item) {
      var response = (await this.$http_in.get(`spv/v1/Customer/${item.PbxCallId}`)).data;
      this.customer = response;
    },
    async setAttachData() {
      let _month = new Date(this.selected_record.StartDate).getMonth()
      var response = (await this.$http_ex.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/AttachData/${this.selected_record._id}/${_month}`, this.attechment_info)).data;
      if (response.ResultCode == 0) {
        this.getCallDetails(this.selected_record);
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.attechment_info = {
        Second: 0,
        Comment: ''
      };

    },
    async SendEmail() {
      var call_details = (await this.$http_in.get(`spv/v1/CallDetails/${this.selected_record.PbxCallId}`)).data;
      let _url = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${call_details.RecordingFile}`;
      let _month = new Date(this.selected_record.StartDate).getMonth();
      var response = (await this.$http_ex.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/SendMail/${this.selected_record._id}/${_month}`, {
        ...this.email_info,
        "Link": _url,
        "Organizations": false,
        "OrganizationId": this.selected_record.Station.OrganizationId,
        "Organization": this.selected_record.Station.OrganizationName,
        "UserId": this.selected_record.Station.Name,
        "Duration": this.selected_record.Duration,
        "QueueExtension": this.selected_record.QueueExtension,
        "StartDate": this.selected_record.StartDate
      })).data;
      if (response.ResultCode == 0) {
        this.getCallDetails(this.selected_record);
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.email_info = {
        Me: true,
        Agent: false,
        CC: '',
        Subject: 'Çağrı Kaydı Maili',
        SendLink: false,
        Explanation: '',
        Details: false,
      };

    },
    searchAndHighlightKeyword(event) {
      if (this.searchKeyword === '') {
        this.clearHighlights();
        return;
      }

      if (event && event.key === 'Enter') {
        if (this.totalMatches > 0) {
          this.currentMatchIndex = (this.currentMatchIndex + 1) % this.totalMatches;
          this.highlightAndScrollToCurrentMatch();
        }
        return;
      }

      this.clearHighlights();

      const keywords = this.searchKeyword.toLowerCase().split(',').filter(e => e.trim() !== '');

      // Find matches
      this.matchIndices = [];
      keywords.forEach(keyword => {
        this.messages.forEach((message, messageIndex) => {
          message.Words.forEach((word, wordIndex) => {
            if (word.Name.toLowerCase() == keyword.trim()) {
              this.$set(word, 'highlight', true);
              this.matchIndices.push({ messageIndex, wordIndex });
            }
          });
        });
      });

      this.totalMatches = this.matchIndices.length;

      if (this.totalMatches > 0) {
        this.currentMatchIndex = 0;
        this.highlightAndScrollToCurrentMatch();
      }
    },

    clearHighlights() {
      this.messages.forEach(message => {
        message.Words.forEach(word => {
          word.highlight = false;
        });
      });
      this.matchIndices = [];
      this.totalMatches = 0;
      this.currentMatchIndex = 0;
    },

    highlightAndScrollToCurrentMatch() {
      if (this.totalMatches > 0) {
        const { messageIndex, wordIndex } = this.matchIndices[this.currentMatchIndex];
        this.scrollToWord(messageIndex, wordIndex);
      }
    },

    scrollToWord(messageIndex, wordIndex) {
      this.$nextTick(() => {
        const messageDiv = this.$refs.msg_div;
        const targetMessages = messageDiv.querySelectorAll('.msg_from, .msg_to');
        const targetMessage = targetMessages[messageIndex];
        const targetWords = targetMessage.querySelectorAll('span');
        const targetWord = targetWords[wordIndex];

        if (targetMessage && targetWord) {
          console.log(`Scrolling to message ${messageIndex}, word ${wordIndex}`);
          console.log(`Message: ${targetMessage.innerText}`);
          console.log(`Word: ${targetWord.innerText}`);

          setTimeout(() => {
            targetWord.scrollIntoView({ behavior: 'smooth', block: 'center', });
          }, 100);
        } else {
          console.warn('Target message or word not found:', targetMessage, targetWord);
        }
      });
    },
    rowClassRecords(item) {
      console.log("item", item);
      if (this.selected_record && this.selected_record.PbxCallId == item.PbxCallId) {
        return "table-primary table-style text-dark font-weight-bolder"
      }

    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.selected_queues = [];
      this.selected_queues = Array.from(
        new Set([...this.selected_queues, ...queues])
      );
      // this.get_live_agent();
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;
      this.users = response;
      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      let default_push = this.selected_queues.length > 0 ? false : true;
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          this.merged_queues.push({
            ...item,
            _type: 'queue'
          })
        }
      }

      this.filter_values.Queues = this.queues.map(e => e.display_name);
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      let _queue_group = response.map(function (obj) {
        return { ...obj, _type: "queue_group" };
      });
      this.merged_queues = [...this.merged_queues, ..._queue_group];


      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)

      this.selected_queues = Array.from(new Set([...this.selected_queues, ...queues]));
      this.getLiveQueue();
      this.getCallBackSuccessCount();
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    GetKeyWordCategories: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(
          `/spv/v1/KeywordCategory`
        )
      ).data;

      this.keyword_categories = response;
      this.is_progress = false;
    },
    categorizeMessages() {
      console.log("categorizeMessages called"); // Kontrollü adım
      try {
        const allKeywords = this.keyword_categories.flatMap(rule => {
          return rule.rules.flatMap(r => {
            return r.keywords.map(keyword => ({
              ...keyword,
              category: rule.display_name,
            }));
          });
        });

        const categorizedMessages = this.messages.map(message => {
          const categorizedWords = message.Words.map(word => {
            const matchedKeyword = allKeywords.find(kw => {
              const inFirstSeconds = kw.in_first_seconds === 0 || word.Start <= kw.in_first_seconds;
              const inLastSeconds = kw.in_last_seconds === 0 || word.End >= kw.in_last_seconds;
              const occurrenceCount = message.Words.filter(w => w.Name === kw.keyword).length;
              const meetsOccurrence =
                (kw.min_occurrence === 0 || occurrenceCount >= kw.min_occurrence) &&
                (kw.max_occurrence === 0 || occurrenceCount <= kw.max_occurrence);
              return kw.keyword === word.Name && kw.speaker === message.type && inFirstSeconds && inLastSeconds && meetsOccurrence;
            });
            return matchedKeyword
              ? { ...word, category: matchedKeyword.category, speaker: matchedKeyword.speaker, reason: matchedKeyword }
              : { ...word, category: "Uncategorized", speaker: "Unknown", reason: null };
          });
          return { ...message, keywords: categorizedWords };
        });

        const result = categorizedMessages.map(message => ({
          display_name: message.display_name,
          categorized_keywords: message.keywords.filter(kw => kw.category !== "Uncategorized").map(kw => ({
            keyword: kw.Name,
            category: kw.category,
            speaker: kw.speaker,
            reason: kw.reason
          }))
        }));
        console.log("result", result);

        let _result = {};
        for (const item of result.filter(e => e.categorized_keywords.length > 0)) {
          if (!_result[item.categorized_keywords[0].category]) {
            _result[item.categorized_keywords[0].category] = { keywords: [] };
          }
          _result[item.categorized_keywords[0].category]["keywords"].push(item.categorized_keywords[0].keyword);
          _result[item.categorized_keywords[0].category]["keyword"] = Array.from(new Set(_result[item.categorized_keywords[0].category]["keywords"])).join(',');
        }


        return _result;
      } catch (error) {
        console.error("Error in categorizeMessages:", error);
      }
    },

    findAndTagOverlappingWords(messages) {
      messages.sort((a, b) => a.Start - b.Start);

      for (let i = 0; i < messages.length - 1; i++) {
        const words1 = messages[i].Words;
        for (let j = i + 1; j < messages.length; j++) {
          const words2 = messages[j].Words;

          words1.forEach(word1 => {
            words2.forEach(word2 => {
              if ((word1.Start <= word2.Start && word1.End >= word2.Start) ||
                (word2.Start <= word1.Start && word2.End >= word1.Start)) {
                word1.overlapping = true;
                word2.overlapping = true;
              }
            });
          });
        }
      }

      return messages;
    },
    isWordPlaying(word) {
      return this.currentTime >= word.Start && this.currentTime < word.End;
    },
    processDataSet(dataSet) {
      this.speechRegions = dataSet.Channels.flatMap(channel => channel.SpeechRegions);
    },
  },
  mounted: async function () {

    try {
      this.is_progress = true;
      this.finish_codes = globalThis.finish_codes;
      await this.GetKeyWordCategories();

      await this.get_users();

      await this.GetQueues();
      // await this.GetQueueGroup();

    } catch (error) {

    } finally {
      this.is_progress = false;

    }

    // await this.GetProjects();
    // await this.get_live_agent();

    // await this.GetDashboardData();

    // this.interval = setInterval(() => {
    //   _self.get_inbound_statistics();
    //   _self.get_live_agent();
    // }, 60000);
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/bootstrap-extended/include";

.daterangepicker {
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;
}
.daterangepicker td.in-range {
  background-color: #bfb9fa;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.reportrange-text {
  padding: 8px 10px !important;
}

.daterangepicker td.active:hover {
  background-color: #7367f0;
  border-color: transparent;
  color: #fff;
}

.daterangepicker .ranges li.active {
  background-color: #7367f0;
  color: #fff;
}

.daterangepicker select.hourselect {
  text-align: center;
}
.daterangepicker select.minuteselect {
  text-align: center;
}

#fdwaveform {
  cursor: pointer;
  /* position: relative; */
}
#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}
#fdwaveform:hover #hover {
  opacity: 1;
}
#time,
#duration {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 11px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  color: #ddd;
}
#time {
  left: 0;
}
#duration {
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.highlight {
  background-color: yellow;
}

.custom-dropdown-menu {
  position: absolute;
  top: 0; /* Adjust this value based on your layout */
  left: 0; /* Adjust this value based on your layout */
  z-index: 1050; /* Bootstrap's dropdown z-index value */
  max-height: 300px; /* Set a maximum height to enable scrolling */
  overflow-y: auto; /* Enable scrolling */
}
</style>
